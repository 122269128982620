import Axios from "axios";
import { url } from "../../global";

const fetchDns = (filter) => {
    return (dispatch, getState) => {
        dispatch({
            type: 'REPORT',
            payload: Axios.get(`${url}/delivery-sparepart/dn`, {
                params: {
                    page: filter.page,
                    perpage: filter.perpage,
                    keyword: filter.keyword,
                    ordering: filter.ordering,
                    startDate: filter.filter.startDate,
                    endDate: filter.filter.endDate,
                    customer: filter.filter.customer,
                    scanned: filter.filter.scanned,
                },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            })
        })
    }
}

// const getDns = (id) => {
//     return (dispatch, getState) => {
//         dispatch({
//             type: 'GET_DETAIL',
//             payload: Axios.get(`${url}/delivery-sparepart/dn/details/${id}`, {
//                 headers: {
//                     Authorization: `Bearer ${localStorage.getItem('token')}`
//                 }
//             })
//         })
//     }
// } getDns

const getCustomer = () => {
    return (dispatch, getState) => {
        dispatch({
            type: 'GET_DETAIL',
            payload: Axios.get(`${url}/delivery-sparepart/dn/data/customers`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            })
        })
    }
}

export {fetchDns, getCustomer};