import React, { Fragment } from 'react';
import { Container, Row, Col, Card, CardBody, FormCheckbox, FormRadio, FormSelect } from 'shards-react';
import PageTitle from '../components/common/PageTitle';
import { Redirect } from 'react-router-dom';
import { appName, url } from '../global';
import { Helmet } from 'react-helmet';
import ScrollToTop from '../components/layout/ScrollToTop';
import { withToastManager } from 'react-toast-notifications';
import { fetchDns, getCustomer } from '../store/actions/dnSparepartAction';
import { connect } from 'react-redux';
import Loading from 'react-loading-bar';
import Error500 from './Error500';
import Table from '../components/table/Table';
import Modal from 'react-bootstrap4-modal';
import Axios from 'axios';
import Error403 from './Error403';
import fileDownload from 'js-file-download';
import RangeDatePicker from '../components/common/RangeDatePicker';

class DeliveryNoteSparepart extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			search: null,
			page: 1,
			perpage: 10,
			keyword: null,
			alert: true,
			alertMsgBox: false,
			deleteIdQrcode: null,
			showMsgBox: false,
			isDeleted: false,
			ordering: {
				type: 'created_at',
				sort: 'desc'
			},
			modal: false,
			file_import: null,
			import: 'Choose file...',
			importing: false,
			// dn_type: 'ADM',
			checked: [],
			download: false,
			modalPrint: false,
			printParams: {
				isNew: false,
				paper: "A4",
				id: "",
			},
			modalDelete: false,
			totalDelete: 0,
			deleteData: [],
			template: '2',
			downloadLoading: false,
			printed: false,
			detailsLoading: false,
			details: {
				module: "",
				data: [],
				loading: false,
				open: false,
				page: 1,
				perpage: 10,
				keyword: null,
				ordering: {
					type: "created_at",
					sort: "asc",
				},
				checked: [],
				print: false,
				selectedDetailsPart: [],
			},
			child: {
				id: null,
				data: [],
				loading: false,
				open: false,
				page: 1,
				perpage: 10,
				keyword: null,
				ordering: {
					type: "created_at",
					sort: "asc",
				},
				checked: [],
				print: false,
			},
			detailsOpen: false,
			subDetailOpen: false,
			filter: {
				startDate: '',
				endDate: '',
				customer: '',
				scanned: ''
			},
			printType: ''
		}

		this.handleDateRangeChange = this.handleDateRangeChange.bind(this);
	}

	handleSorting = (e) => {
		const type = e.target.id;
		const sort = this.state.ordering.sort;
		this.setState({
			...this.state,
			ordering: {
				type: type,
				sort: sort === 'asc' ? 'desc' : 'asc'
			}
		});
	}

	handleChangeKeyword = (e) => {
		this.setState({
			...this.state,
			[e.target.id]: e.target.value
		});
	}

	handleSubmitKeyword = (e) => {
		e.preventDefault();
		this.props.fetchDns(this.state);
	}

	handleClickPage = (e) => {
		this.setState({
			...this.state,
			page: e
		});
	}

	hanldeChangePage = (e) => {
		this.setState({
			...this.state,
			perpage: e.target.value
		});
	}

	handleClickDelete = (id) => {
		this.setState({
			...this.state,
			deleteIdQrcode: id,
			showMsgBox: true
		});
	}

	handleClickYes = () => {

		this.setState({
			...this.state,
			alertMsgBox: true,
			showMsgBox: false,
			isDeleted: true
		});

		this.props.deleteDn(this.state.deleteIdQrcode);
	}

	handleClickNo = () => {
		this.setState({
			...this.state,
			showMsgBox: false,
			deleteIdQrcode: null
		});
	}

	handlePopupPrint = () => {
		const filter = this.state.checked.map(item => { return item.isChecked })

		if (filter.includes(true)) {
			this.setState({
				...this.state,
				modalPrint: true
			})
		} else {
			const { toastManager } = this.props;
			toastManager.add('Please select at least one item!', {
				appearance: 'error',
				autoDismiss: true
			});
		}
	}

	handlePrintSelect = (e) => {
		const { name, value } = e.target;
		const newState = {
			...this.state,
			printParams: {
				...this.state.printParams,
				[name]: value !== "" ? value : null,
		  	},
		};
		this.setState(newState);
	};

	handlePrintDetailSelect = (e) => {
		const { name, value } = e.target;
		const newState = {
			...this.state,
			printParams: {
				  ...this.state.printParams,
				  [name]: value !== "" ? value : null,
			},
		  	details: {
				...this.state.details,
		  	},
		};
		this.setState(newState);
	};

	handlePrintChildSelect = (e) => {
		const { name, value } = e.target;
		const newState = {
			...this.state,
			printParams: {
				  ...this.state.printParams,
				  [name]: value !== "" ? value : null,
			},
			child: {
				...this.state.child,
		  	},
		};
		this.setState(newState);
	};

	componentWillUpdate(nextProps, nextState) {
		if (this.state.page !== nextState.page) {
			this.props.fetchDns(nextState);
		}

		if (this.state.perpage !== nextState.perpage) {
			this.props.fetchDns(nextState);
		}

		if (this.state.ordering !== nextState.ordering) {
			this.props.fetchDns(nextState);
		}

		if (this.state.printed !== nextState.printed) {
			this.props.fetchDns(nextState);
		}
	}

	componentDidUpdate = (prevProps, prevState) => {

		if (prevProps.error !== this.props.error) {
			if (!this.props.fetched) {
				if (this.props.error) {
					const { toastManager } = this.props;
					toastManager.add(this.props.error.data.message, {
						appearance: 'error',
						autoDismiss: true
					});
				}
			}
		}

		if (prevProps.isDeleted !== this.props.isDeleted) {
			if (this.props.isDeleted) {
				const { toastManager } = this.props;
				toastManager.add(this.props.message, {
					appearance: 'success',
					autoDismiss: true
				});
				this.props.fetchDns(this.state);
			}
		}

		if (prevProps.payload !== this.props.payload) {
			const data = this.props.payload.data && this.props.payload.data.data.map(item => {
				return {
					dn: item.dn,
					isChecked: false
				}
			})

			this.setState({
				...this.state,
				checked: data
			})

		}

	}

	handleModal = () => {
		this.setState({
			...this.state,
			modal: true
		})
	}

	modalBackdropClicked = () => {
		this.setState({
			...this.state,
			modal: false,
			modalPrint: false,
			modalDelete: false,
			details: {
				...this.state.details,
				print: false
			},
			child: {
				...this.state.child,
				print: false
			},
			// deleteData: [],
		});
	}

	componentDidMount = () => {
		this.props.fetchDns(this.state)
		this.props.getchCustomers()
	}

	handleChangeFileImport = (e) => {

		if (e.target.files.length > 0) {

			const file = e.target.files[0]
			this.setState({
				...this.state,
				import: file.name,
				file_import: file
			})

		}
	}

	handleImport = () => {
		const { toastManager } = this.props;

		this.setState({
			...this.state,
			importing: true
		});

		const fd = new FormData();
		fd.append('file', this.state.file_import);
		fd.append('dn_type', this.state.dn_type);

		Axios.post(`${url}/delivery-sparepart/dn/import`, fd,
			{
				headers: {
					Authorization: `Bearer ${localStorage.getItem('token')}`,
					'Conten-Type': 'multipart/form-data'
				}
			}).then(res => {

				this.setState({
					...this.state,
					modal: false,
					importing: false,
					file_import: null,
					import: 'Choose file...'
				});

				toastManager.add(res.data.message, {
					appearance: 'success',
					autoDismiss: true
				});

				this.fileImport.value = ''

				this.props.fetchDns(this.state);

			})
			.catch(error => {

				this.setState({
					...this.state,
					modal: false,
					importing: false,
					file_import: null,
					import: 'Choose file...'
				});

				this.fileImport.value = ''

				toastManager.add(error.response.data.message, {
					appearance: 'error',
					autoDismiss: true
				});

			});
	}

	handleCopies = (event) => {

		const { toastManager } = this.props;

		Axios.put(`${url}/qr-code/${event.target.dataset.value}`, {
			copies: event.target.value
		}, {
			headers: {
				Authorization: `Bearer ${localStorage.getItem('token')}`
			}
		}).then(res => {

			toastManager.add(res.data.message, {
				appearance: 'success',
				autoDismiss: true
			});

		}).catch(error => {

			toastManager.add(error.response.data.message, {
				appearance: 'error',
				autoDismiss: true
			});

		})

		let checked = this.state.checked

		checked.forEach(checked => {
			if (checked.id === event.target.dataset.value)
				checked.copies = event.target.value
		})

		this.setState({
			...this.state,
			checked: checked
		})
	}

	handleCheckAll = () => {

		let checked = this.state.checked
		checked.forEach(check => check.isChecked = true)
		this.setState({ checked: checked })


	}

	handleUnCheckAll = () => {
		let checked = this.state.checked
		checked.forEach(check => check.isChecked = false)
		this.setState({ checked: checked })

	}

	handleToggleCheck = () => {

		let checked = this.state.checked

		checked.forEach(checked => {
			checked.isChecked = checked.isChecked ? false : true
		})

		this.setState({ checked: checked })

	}

	handleChangeTemplate = (e) => {

		this.setState({
			...this.state,
			template: e.target.value
		})
	}

	handlePrint = async () => {
		const { toastManager } = this.props;
		this.setState({
			...this.state,
			download: true
		});

		const params = {
			type: this.state.printType,
			paper_size: this.state.printParams.paper || 'Thermal',
			delivery_notes: (
				this.state.checked
					.filter(item => item.isChecked === true).length <= 0 ? 
						Object.keys(this.state.details.open) : this.state.checked
							.filter(item => item.isChecked === true)
							.map(item => item.dn)
			),
			part_numbers: (
				this.state.details.checked
					.filter(item => item.isChecked === true).length <= 0 ?
						Object.keys(this.state.child.open) : this.state.details.checked
							.filter(item => item.isChecked === true)
							.map(item => item.part_number)
			),
			qrcodes: this.state.child.checked
				.filter(item => item.isChecked === true)
				.map(item => item.qrcode)
		};
		
		await Axios.post(`${url}/delivery-sparepart/dn/print`, params, {
			responseType: 'blob',
			headers: {
				Authorization: `Bearer ${localStorage.getItem('token')}`
			}
		}).then(response => {
			// this.modalBackdropClicked();
			this.setState({
				...this.state,
				download: false
			});

			const file = new Blob([response.data], { type: 'application/pdf' });
			const fileURL = URL.createObjectURL(file);
			window.open(fileURL, "_blank");

			this.setState({
				...this.state,
				modalPrint: false
			});
			this.props.fetchDns(this.state);

		}).catch(error => {
			this.setState({
				...this.state,
				download: false
			});

			toastManager.add(error.response.data.message, {
				appearance: 'error',
				autoDismiss: true
			});
		})
	}

	handleDateRangeChange(startDate, endDate) {
		this.setState({
			filter: {
				...this.state.filter,
				startDate,
				endDate
			}
		});
		if (this.state.filter.startDate) {
			this.props.fetchDns({ ...this.state, filter: { ...this.state.filter, endDate } })
		}
	}

	detailsTable = (id) => {
		const { checked, data } = this.state.details;
		const isSelected = checked && checked.filter((item) => item.isChecked === true).length > 0;
		return (
			<tr>
				<td colSpan="18" className="py-0">
					<div className="row">
						<div className="col-12">
							<button className="btn btn-secondary mx-2" disabled={!isSelected}
								onClick={(e) => {
									this.handlePopupPrintDetail(e, id);
								}}
							>
								<i className="mdi mdi-printer" /> Print selected
							</button>
						</div>
						<div className="col-md-12">
							<table className="table table-bordered">
								<thead>
									<tr>
										<td>Part Number</td>
										<td>Name</td>
										<td>Qty Order</td>
										<td>Total Kanban</td>
										<td>Total Qty Scanned</td>
										<td>Total Kanban Scanned</td>
										<td>Status</td>
									</tr>
								</thead>
								<tbody>
									{this.state.detailsLoading ? (
										<tr>
											<td className="text-center" colSpan="7">
												<i className="mdi mdi-loading mdi-spin mr-2"></i> Loading ...
											</td>
										</tr>
									) : this.state.details.data[id] && this.state.details.data[id].length > 0 ? (
										this.state.details.data[id].map((detail, index) => {
											return (
												<Fragment key={index}>
													<tr>
														<td>
															<FormCheckbox checked={detail.isChecked} value={detail.part_no}
																onChange={(e) =>
															  		this.handleDetailCheckBox(e, id)
																}
														  	>
																{detail.part_no}
														  	</FormCheckbox>
														  	<p className="text-center">
																<button className="btn btn-link btn-sm text-info"
															  		type="button"
															  		onClick={() => this.getChildDetails(detail.part_no)}
																>
															  	<i className={`mdi ${
																	this.state.child.open[detail.part_no]
																		? "mdi-chevron-down"
																		: "mdi-chevron-right"
																} mr-2`}></i>
															  		Show Child
																</button>
														  	</p>
														</td>
														<td>{detail.part_name}</td>
														<td>{detail.order_qty}</td>
														<td>{detail.total_kanban}</td>
														<td>{detail.qty_order_scanned}</td>
														<td>{detail.total_kanban_scanned}</td>
														<td
														  className={`text-center text-white ${
															detail.status === undefined || detail.status === "Open"
															  ? "bg-danger"
															  : "bg-success"
														  }`}>
														  	{detail.status || "Open"}
														</td>
													</tr>
														{this.state.child.open[detail.part_no] &&
															this.childTable(detail.part_no)}
												</Fragment>
											);
										})
									) : (
										<tr>
											<td className="text-center" colSpan="7">
												Data not found
											</td>
										</tr>
									)}
								</tbody>
							</table>
						</div>
					</div>
				</td>
			</tr>
		)
	}

	getDetails = (mod) => {
		this.setState({
			...this.state,
			details: {
				...this.state.details,
				loading: true,
				open: {
			  		[mod]: !this.state.details.open[mod] ? true : false,
				},
		  	},
		});

		Axios.get(`${url}/delivery-sparepart/dn/details/${mod}`, {
			headers: {
				Authorization: `Bearer ${localStorage.getItem("token")}`,
		  	},
		}).then((res) => {
			const data = { [mod]: res.data.data };
			
			const checked =
				res.data.data &&
				res.data.data.map((item) => {
					return {
						module: item.module,
						part_number: item.part_no,
						isChecked: false,
					};
				});
	
			this.setState({
				...this.state,
				details: {
			  		...this.state.details,
			  		loading: false,
			  		data,
			  		checked,
				},
		  	});
		});
	};

	childTable = (id) => {
		const theads = [
			{ name: "code", value: `Qr Code`, sortable: true },
			{ name: "generated_at", value: `Generated At`, sortable: false },
			{ name: "scanned", value: `Scan Status`, sortable: false },
		];
	
		const { ordering, data, loading, page, checked } = this.state.child;
		const isSelected = checked.filter((item) => item.isChecked === true).length > 0;
	
		const details = data[id] && data[id].length > 0 ? (
			checked.map((detail, index) => {
				return (
					<tr key={index}>
				  		<td>
							<FormCheckbox checked={detail.isChecked}
					  			value={detail._id}
					  			onChange={(e) => this.handleChildCheckBox(e, id)}
							>
					  			{detail.qrcode}
							</FormCheckbox>
				  		</td>
				  		<td>{detail.created_at}</td>
				  		<td className={`text-center text-white ${
								detail.scan_status === "Close" || detail.scan_status === "Close With Delay" ? "bg-success" : 
								detail.scan_status === "Open" ? "bg-danger" : 
								detail.scan_status === "Waiting Quality Check" || detail.scan_status === "Delay" ? "bg-warning" : "" 
							}`}>
							{detail.scan_status}
				  		</td>
					</tr>
			  	);
			})
		) : (
			<tr>
				<td className="text-center" colSpan="4">
					Data not found
			  	</td>
			</tr>
		);
	
		return (
			<tr>
				<td colSpan="18" className="py-0">
			  		<div className="row">
						<div className="col-12">
				  			<button className="btn btn-secondary mx-2" disabled={!isSelected}
								onClick={(e) => this.handlePopupPrintChild(e, id)}
				  			>
								<i className="mdi mdi-printer" /> Print Selected
				  			</button>
						</div>
			  		</div>
					<div className="row">
						<div className="col-md-12">
							<Table theads={theads} ordering={ordering} handleSorting={this.handleDetailSorting}>
								{loading ? (
									<tr>
										<td className="text-center" colSpan="4">
											<i className="mdi mdi-loading mdi-spin mr-2"></i>Loading ...
										</td>
									</tr>
								) : (
								details
								)}
							</Table>
						</div>
					</div>
				</td>
		  	</tr>
		);
	};

	getChildDetails = (part_number) => {
		this.setState({
			...this.state,
			child: {
				...this.state.child,
				loading: true,
				open: {
			  		[part_number]: !this.state.child.open[part_number] ? true : false,
				},
		  	},
		});
	
		const module = Object.keys(this.state.details.open)[0];
		
		Axios.get(`${url}/delivery-sparepart/dn/details/${module}`, {
			params: {
				part_no: part_number
			},
			headers: {
				Authorization: `Bearer ${localStorage.getItem("token")}`,
		  	},
		}).then((res) => {
			const data = { [part_number]: res.data.data };
			
			const checked = res.data.data && res.data.data.map((item) => {
				return {
					...item,
					isChecked: false,
			  	};
			});
	
			this.setState({
				...this.state,
				child: {
			  		...this.state.child,
			  		loading: false,
			  		data,
			  		checked,
				},
		  	});
		});
	};

	handleCheckBox = (event) => {
		let checked = this.state.checked
		
		checked.forEach(c => {
			if (c.dn === event.target.value)
				c.isChecked = event.target.checked
		})

		this.setState({
			...this.state,
			checked: checked,
			printType: 'dn'
		})
	}

	handleDetailCheckBox = (event) => {
		let checked = this.state.details.checked
		checked.forEach(c => {
			if (c.part_number === event.target.value)
				c.isChecked = event.target.checked
		})

		this.setState({
			...this.state,
			printType: 'parts',
			details: {
				...this.state.details,
				checked
			}
		})
	};

	handleChildCheckBox = (event) => {
		let checked = this.state.child.checked
		checked.forEach(c => {
			if (c._id === event.target.value)
				c.isChecked = event.target.checked
		})

		this.setState({
			...this.state,
			printType: 'qrcodes',
			child: {
				...this.state.child,
				checked
			}
		})
	};

	handlePopupPrintDetail = (e, id = "") => {
		const checkedDetail = this.state.details.checked.filter((detail) => detail.isChecked).map((item) => item.part_number)
		this.setState((prevState) => {	
			const updatedDetails = {
				...prevState.details, // Make sure to spread prevState.details
				print: true,
				selectedDetailsPart: checkedDetail,
			};
	
			if (id) {
				updatedDetails.printParams = { ...prevState.details.printParams, id };
			}
			
			return { details: updatedDetails }; // Update 'details' correctly
		});
	};

	handlePopupPrintChild = (e, part_number, id = "") => {
		const checkedDetail = this.state.child.checked.filter((detail) => detail.isChecked).map((item) => item.code)
		this.setState((prevState) => {
			const updatedChild = {
				...prevState.child, // Make sure to spread prevState.details
				print: true,
				selectedCodes: checkedDetail,
			};
	
		  	return { child: updatedChild }; // Update 'details' correctly
		});
	};

	handleChangeCustomer = (e) => {
		var newData = {
			...this.state,
			filter: {
				...this.state.filter,
				customer: e.target.value
			}
		}
		this.setState(newData)
		this.props.fetchDns(newData)
	}

	handleChangeScanned = (e) => {
		var newData = {
			...this.state,
			filter: {
				...this.state.filter,
				scanned: e.target.value
			}
		}
		this.setState(newData)
		this.props.fetchDns(newData)
	}

	handleDownloadTemplate = async () => {
		const { toastManager } = this.props;
		this.setState({
			...this.state,
			downloadLoading: true
		});

		await Axios.get(`${url}/delivery-sparepart/dn/import/template`, {
			responseType: 'blob',
			headers: {
				Authorization: `Bearer ${localStorage.getItem('token')}`
			}
		}).then(response => {

			fileDownload(response.data, `template_dn_sparepart.xlsx`);

			this.setState({
				...this.state,
				downloadLoading: false
			});

		}).catch(error => {

			this.setState({
				...this.state,
				downloadLoading: false
			});


			toastManager.add(error.response.data.message, {
				appearance: 'error',
				autoDismiss: true
			});

		})
	}

	handlePopupDelete = () => {
		const deleteData = this.state.checked.filter(item => item.isChecked === true).map(item => item.dn);
		const data = this.props.payload.data.data.filter(item => deleteData.includes(item.dn) && item.qty_order_scanned == 0);
		
		this.setState({
			...this.state,
			modalDelete: true,
			totalDelete: data.length,
			deleteData: data
		});
	}

	handleDelete = () => {
		const { deleteData } = this.state;
		const { toastManager, fetchDns } = this.props;
		Axios.post(`${url}/delivery-sparepart/dn/delete`, {
			data: deleteData.map(item => item._id)
		}, {
			headers: {
				Authorization: `Bearer ${localStorage.getItem('token')}`
			}
		}).then(res => {			
			toastManager.add(res.data.message, {
				appearance: 'success',
				autoDismiss: true
			})
			this.modalBackdropClicked()
			fetchDns(this.state)
		}).catch(error => {
			toastManager.add(error.response.data.message, {
				appearance: 'error',
				autoDismiss: true
			});
		})
	}

	handleDateChange = (dateString) => {
		const date = new Date(dateString);
		if (isNaN(date)) {
			throw new Error("Invalid date format");
		}

		const day = String(date.getDate()).padStart(2, '0');
		const month = String(date.getMonth() + 1).padStart(2, '0');
		const year = date.getFullYear();

		return `${day}-${month}-${year}`;
	}

	render() {
		const { payload, error, fetching, customer } = this.props;
		if (!localStorage.getItem('token')) return <Redirect to="/login" />
		if (error && error.status === 500) return <Error500 message={error.data.message} />
		if (error && error.status === 403) return <Error403 message={error.data.message} />

		const { ordering, dn_type, filter } = this.state;
		const theads = [
			{ name: 'dn_number', 'value': `Delivery Note #`, sortable: true },
			{ name: 'customer_name', 'value': 'Customer', sortable: true },
			{ name: 'qty_order', 'value': 'Qty Order', sortable: true },
			{ name: 'total_kanban', 'value': 'Total Kanban', sortable: true },
			{ name: 'total_qty_scanned', 'value': 'Total Qty Scanned', sortable: true },
			{ name: 'total_kanban_scanned', 'value': 'Total Kanban Scanned', sortable: true },
			{ name: 'delivery_date', 'value': 'Delivery Date', sortable: true },
			{ name: "status", value: `Status`, sortable: false },
		];
		
		
		const dnss = payload.data && payload.data.data.map((dn, index) => {
			const checked = this.state.checked[index]
			
			return (
				<Fragment key={dn.dn}>
					<tr>
						<td>
							<FormCheckbox checked={checked && checked.isChecked ? true : false} value={dn.dn} onChange={this.handleCheckBox}><span className="text-primary">
								<strong>{dn.dn}</strong></span>
							</FormCheckbox>
							<p className="text-center">
								<button className="btn btn-link btn-sm text-info" type="button" onClick={() => this.getDetails(dn.dn)}><i className={`mdi ${this.state.detailsOpen[dn.dn] ? 'mdi-chevron-down' : 'mdi-chevron-right'} mr-2`}></i>Show Details</button>
							</p>
						</td>
						<td>{dn.customer} </td>
						<td>{dn.total_order_qty} </td>
						<td>{dn.total_kanban} </td>
						<td>{dn.qty_order_scanned}</td>
			            <td>{dn.total_kanban_scanned}</td>
						<td>{this.handleDateChange(dn.schedule_delivery)}</td> 
						<td className={`text-center text-white ${
							dn.status === undefined || dn.status === "Open" ? "bg-danger" : "bg-success"
						}`}>
							{dn.status|| "Open"}
						</td>
					</tr>
					{
						this.state.details.open[dn.dn] && (
							this.detailsTable(dn.dn)
						)
					}
				</Fragment>
			);
		});

		return (
			<Container fluid className="main-content-container px-4">
				<Loading
					show={fetching}
					color="blue"
					showSpinner={false}
				/>
				<Helmet>
					<title>Delivery Note Sparepart | {appName} </title>
				</Helmet>
				<Row noGutters className="page-header py-4">
					<PageTitle sm="4" title="Delivery Note Sparepart" className="text-sm-left" />
				</Row>

				{/* Modal Import Data */}
				<Modal visible={this.state.modal} onClickBackdrop={this.modalBackdropClicked}>
					<div className="modal-header">
						<h5 className="modal-title">Import Delivery Note Service</h5>
					</div>
					<div className="modal-body py-0 pt-2 px-4">
						<div className="row">
							<div className="custom-file">
								<input id="import" type="file" className="custom-file-input" onChange={this.handleChangeFileImport}
									accept=".xlsx, .xls, .csv"
									ref={ref => this.fileImport = ref}
								/>
								<label className="custom-file-label" htmlFor="customFile2" id="placeholderCustomFile2">
									{this.state.import}
								</label>
							</div>
							<div className="mb-3">
								{this.state.downloadLoading ? (
									<button className="btn text-success btn-disabled p-0 mx-2" disabled onClick={this.handleDownloadTemplate}><i className="mdi mdi-loading mdi-spin mr-2"></i>Loading...</button>
								) : (
									<button className="btn text-success p-0 my-2" onClick={this.handleDownloadTemplate}><i className="mdi mdi-download mr-2"></i>Download upload template</button>
								)}
								<div>
									<small>
										*) File format must be xlsx, xls or csv (semicolon separator ';')<br />
										*) Warning! existing data will be overwritted, be careful! <br />
										*) Max file size 1MBs <br />
									</small>
								</div>
							</div>
						</div>
					</div>
					<div className="modal-footer">
						<button type="button" className="btn btn-default" onClick={this.modalBackdropClicked}>
							Close
						</button>
						{this.state.importing ? (
							<button type="button" className="btn btn-secondary btn-disabled" disabled>
								<i className="mdi mdi-loading mdi-spin"></i> Processing...
							</button>
						) : (
							<button type="button" className="btn btn-secondary" onClick={this.handleImport}>
								Import
							</button>
						)}
					</div>
				</Modal>

				{/* Modal Print 1 */}
				<Modal visible={this.state.modalPrint} onClickBackdrop={this.modalBackdropClicked}>
					<div className="modal-header">
						<h5 className="modal-title">Print QR Codes</h5>
					</div>
					<div className="modal-body py-0 pt-2 px-6">
						<div className="form-group">
							<label htmlFor="paper">Paper</label>
							<FormSelect name="paper" id="paper" onChange={this.handlePrintSelect} value={this.state.printParams.paper}>
								<option value="A4">A4</option>
								<option value="Thermal">Thermal</option>
							</FormSelect>
						</div>
					</div>
					<div className="modal-footer">
						<button type="button" className="btn btn-default" onClick={this.modalBackdropClicked}>
							Close
						</button>
						{this.state.download ? (
							<button className="btn btn-secondary btn-disabled" disabled>
								<i className="mdi mdi-loading mdi-spin"></i> Loading...
						  	</button>
						) : (
						  	<button className="btn btn-secondary" onClick={this.handlePrint}>
								<i className="mdi mdi-printer"></i> Print
						  	</button>
						)}
					</div>
				</Modal>

				{/* Modal Print 2 */}
				<Modal visible={this.state.details.print} onClickBackdrop={this.modalBackdropClicked}>
					<div className="modal-header">
						<h5 className="modal-title">Print QR Code</h5>
					</div>
					<div className="modal-body py-0 pt-2 px-6">
						<div className="form-group">
							<label htmlFor="paper">Paper</label>
							<FormSelect name="paper" id="paper" onChange={this.handlePrintDetailSelect} value={this.state.printParams.paper}>
								<option value="A4">A4</option>
								<option value="Thermal">Thermal</option>
							</FormSelect>
						</div>
					</div>
					<div className="modal-footer">
						<button type="button" className="btn btn-default" onClick={this.modalBackdropClicked}>
							Close
						</button>
						{this.state.download ? (
							<button className="btn btn-secondary btn-disabled" disabled>
								<i className="mdi mdi-loading mdi-spin"></i> Loading...
							</button>
						) : (
						  	<button className="btn btn-secondary" onClick={this.handlePrint}>
								<i className="mdi mdi-printer"></i> Print
						  	</button>
						)}
					</div>
				</Modal>

				{/* Modal Print 3 */}
				<Modal visible={this.state.child.print} onClickBackdrop={this.modalBackdropClicked}>
					<div className="modal-header">
						<h5 className="modal-title">Print QR Code Child</h5>
					</div>
					<div className="modal-body py-0 pt-2 px-6">
						<div className="form-group">
							<label htmlFor="paper">Paper</label>
							<FormSelect name="paper" id="paper" onChange={this.handlePrintChildSelect} value={this.state.printParams.paper}>
								<option value="A4">A4</option>
								<option value="Thermal">Thermal</option>
							</FormSelect>
						</div>
					</div>
					<div className="modal-footer">
						<button type="button" className="btn btn-default" onClick={this.modalBackdropClicked}>
							Close
						</button>
						{this.state.download ? (
							<button className="btn btn-secondary btn-disabled" disabled>
								<i className="mdi mdi-loading mdi-spin"></i> Loading...
							</button>
						) : (
							<button className="btn btn-secondary" onClick={this.handlePrint}>
								<i className="mdi mdi-printer"></i> Print
							</button>
						)}
					</div>
				</Modal>

				{/* Modal Delete */}
				<Modal visible={this.state.modalDelete} onClickBackdrop={this.modalBackdropClicked}>
					<div className="modal-body py-0 pt-4 px-4 text-center">
						<div className='d-flex justify-content-center align-items-center mb-3'>
							<div style={{ borderRadius: '9999px', backgroundColor: 'rgb(196 25 60 / 15%)', padding: '1rem', width: '80px' }}>
								<i className="mdi mdi-delete text-danger" style={{ fontSize: '2rem' }} />
							</div>
						</div>
						{this.state.totalDelete > 0 ? (
							<>
								<h3>Are you sure?</h3>
								<p className='text-danger'>This action will permanently delete {this.state.totalDelete} delivery {this.state.totalDelete > 1 ? 'notes' : 'note'} Sparepart.</p>
							</>
						) : (
							<>
								<h3>Warning!</h3>
								<p className='text-warning'>Delivery Note Sparepart has been scanned and cannot be deleted.</p>
							</>
						)}
					</div>
					<div className="modal-footer">
						<button type="button" className="btn btn-default" onClick={this.modalBackdropClicked}>
							Close
						</button>
						{ this.state.totalDelete > 0 ? this.state.importing ? (
							<button type="button" className="btn btn-secondary btn-disabled" disabled>
								<i className="mdi mdi-loading mdi-spin"></i> Processing...
							</button>
						) : (
							<button type="button" className="btn btn-danger" onClick={this.handleDelete}>
								Delete
							</button>
						) : ''}
					</div>
				</Modal>

				<Row>
					{this.state.showMsgBox && (
						<ScrollToTop>
							<div className="messagebox">
								<p className="mb-5">Are you sure want to delete this data?</p>
								<button className="btn btn-secondary mr-4" onClick={this.handleClickYes}>Yes</button>
								<button className="btn btn-default" onClick={this.handleClickNo}>No Cancel</button>
							</div>
							<div className="backdrop"></div>
						</ScrollToTop>
					)}
					<Col>
						<Card small className="mb-4">
							<CardBody className="p-0 pb-3">
								<div className="col-md-12 mt-4">
									<div className="row">
										<div className="col-md-8">
											<div className="row">
												<div className="col-12">
													<button className="btn btn-secondary" onClick={this.handleModal}>
														<i className="mdi mdi-upload" /> Import
													</button>
													<button className="btn btn-secondary mx-2" onClick={this.handleCheckAll}>
														<i className="mdi mdi-check-box-outline" /> Check all
													</button>
													<button className="btn btn-secondary" onClick={this.handleUnCheckAll}>
														<i className="mdi mdi-checkbox-blank-outline" /> Uncheck all
													</button>
													<button className="btn btn-secondary mx-2" onClick={this.handleToggleCheck}>
														<i className="mdi mdi-toggle-switch" /> Toggle Check
													</button>
													<button className="btn btn-secondary mx-2" onClick={this.handlePopupPrint}>
														<i className="mdi mdi-printer" /> Print selected
													</button>
													{this.state.checked && this.state.checked.filter(item => item.isChecked === true).length > 0 &&
														<button className="btn btn-danger mx-2" onClick={this.handlePopupDelete}>
															<i className="mdi mdi-delete" /> Delete
														</button>
													}
												</div>
											</div>
											<div className="row mt-2">
												<div className="col-12">
													<div className="d-flex align-items-center" style={{ gap: '1rem' }}>
														<div className='form-group'>
															<label>Delivery Date</label>
															<RangeDatePicker onDateRangeChange={this.handleDateRangeChange} />
														</div>
														<div className="form-group">
															<label>Customer</label>
															<FormSelect onChange={this.handleChangeCustomer} value={filter.customer}>
																<option value=''>All</option>
																{customer && customer.map((cust) => {
																	return (
																		<option value={cust} key={cust}>{cust}</option>
																	)
																})}
															</FormSelect>
														</div>
														<div className="form-group">
															<label>Scanned</label>
															<FormSelect onChange={this.handleChangeScanned} value={filter.scanned}>
																<option value=''>All</option>
																<option value='1'>Yes</option>
																<option value='0'>No</option>
															</FormSelect>
														</div>
													</div>
												</div>
											</div>

										</div>
										<div className="col-md-4 text-right">
											<form onSubmit={this.handleSubmitKeyword}>
												<div className="input-group mb-3">
													<input
														id="keyword"
														type="text"
														className="form-control"
														placeholder=""
														aria-label="Example text with button addon"
														aria-describedby="button-addon1"
														onChange={this.handleChangeKeyword}
													/>
													<div className="input-group-prepend">
														<button
															className="btn btn-secondary"
															type="submit"
															id="button-addon1"
														>
															<i className="mdi mdi-magnify" /> Search{' '}
														</button>
													</div>
												</div>
											</form>
										</div>
									</div>
								</div>
								<div className="col-md-12 mt-3">
									<Table theads={theads} ordering={ordering} handleSorting={this.handleSorting}>
										{fetching ? (
											<tr>
												<td className="text-center" colSpan="16">Loading...</td>
											</tr>
										) : payload.data && payload.data.data.length > 0 ? dnss : (
											<tr>
												<td className="text-center" colSpan="16">Data not found</td>
											</tr>
										)}
									</Table>
								</div>
								<div className="col-md-12 py-3">
									<div className="row">
										<div className="col-md-10">
											{payload.data && payload.data.total > 1 && (
												<p>Showing {payload.data && payload.data.from.toLocaleString()} to {payload.data && payload.data.to.toLocaleString()} of {payload.data && payload.data.total.toLocaleString()} record(s)</p>
											)}

											{payload.data && payload.data.total > 1 && (
												<nav aria-label="Page navigation example">
													<ul className="pagination">
														{payload.data.current_page > 1 && <li key="prev" className="page-item"><button onClick={this.handleClickPage.bind(null, payload.data.current_page - 1)} className="page-link">Prev</button></li>}
															{payload.data.pages.map((page, index) => {
																return (
																	<li key={index} className={`page-item ${page === '...' ? 'disabled' : ''} ${page === payload.data.current_page ? 'active' : ''}`}><button onClick={this.handleClickPage.bind(null, page)} className="page-link">{page}</button></li>
																)
															})}
														{payload.data.current_page < payload.data.last_page && <li key="next" className="page-item"><button onClick={this.handleClickPage.bind(null, payload.data.current_page + 1)} className="page-link">Next</button></li>}
													</ul>
												</nav>
											)}
										</div>
										<div className="col-md-2 text-right">
											<div className="form-group">
												<label className="control-label">Showing per page </label>
												<select
													defaultValue="10"
													id="perpage"
													className="form-control custom-select"
													onChange={this.hanldeChangePage}
												>
													<option value="10">10</option>
													<option value="20">20</option>
													<option value="50">50</option>
													<option value="100">100</option>
												</select>
											</div>
										</div>
									</div>
								</div>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		);
	}
}



const mapStateToProps = (state) => {
	
	return {
		...state,
		payload: state.deliveryNoteSparepart.payload,
		customer: state.deliveryNoteSparepart.customer,
		error: state.deliveryNoteSparepart.error,
		fetching: state.deliveryNoteSparepart.fetching,
		message: state.deliveryNoteSparepart.message,
		saved: state.deliveryNoteSparepart.saved,
		isDeleted: state.deliveryNoteSparepart.isDeleted
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		fetchDns: (filter) => dispatch(fetchDns(filter)),
		getchCustomers: () => dispatch(getCustomer()),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withToastManager(DeliveryNoteSparepart));
