import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "shards-react";
import PageTitle from "../components/common/PageTitle";
import { appName, customerStyles, url, socketurl } from "../global";
import { Helmet } from "react-helmet";
import { Redirect } from "react-router-dom";
import { withToastManager } from "react-toast-notifications";
import Loading from "react-loading-bar";
import Error500 from "./Error500";
import Error403 from "./Error403";
import Axios from "axios";
import socketIOClient from "socket.io-client";
import Table from "../components/table/Table";

class ScanShoppingSparepart extends React.Component {
	state = {
		code: "",
		qrcode: "",
		fetching: false,
		error: null,
		payload: {},
		isAdmin: localStorage.getItem("openLock") === "true",
		isKanbanLock: localStorage.getItem("isScanShoppingLock"),
		kanbanLockData: [],
		hasLockPage: false,
		lockpageOrdering: {
			type: "locked_at",
			sort: "desc",
		},
		password: "",
		passwordError: "",
  	};

	handleChange = (e) => {
    	this.setState({
      		...this.state,
      		[e.target.id]: e.target.value,
    	});
  	};

  	handleKeyDown = (e) => {
    	if (e.key === "Enter") {
      		this.getKanban(this.state.qrcode);
    	}
  	};

  	getKanban = (qrcode) => {
  		Axios.post(`${url}/delivery-sparepart/scan/shopping/internal`, { qrcode_internal: qrcode }, {
  	      	headers: {
  	        	Authorization: `Bearer ${localStorage.getItem("token")}`,
  	      	},
  	    }).then((res) => {
  	    	this.setState({
  	    		...this.state,
  	    		error: null,
  	    		qrcode: "",
  	    	});

  	    	const { toastManager } = this.props;
  	    	toastManager.add(res.data.message, {
  	    		appearance: "success",
  	    		autoDismiss: true,
  	    	});
  		}).catch((err) => {
  			this.setState({
  	    		...this.state,
  	    		error: err.response,
  	    		payload: {},
  	  		});
  	    }).finally(() => this.checkPage());
  	};

	checkPage = () => {
    	Axios.get(`${url}/delivery-sparepart/scan/shopping/check-page`, {
      		headers: {
        		Authorization: `Bearer ${localStorage.getItem("token")}`,
      		},
    	}).then((res) => {
        	const hasLockPage = res.data.length > 0 ? true : false;
        	this.setState({
        		...this.state,
        	  	isKanbanLock: this.state.isAdmin && hasLockPage ? false : true,
        	  	kanbanLockData: {
        	    	data: res.data,
        	    	isModalOpen: hasLockPage,
        	  	},
        	  	hasLockPage: hasLockPage,
        	});
        	localStorage.setItem(
        		"isScanShoppingLock",
          		!this.state.isAdmin && hasLockPage ? res.data[0].lock : false
        	);
      	}).catch((error) => {
    		if (Axios.isCancel(error)) {
          		console.log("Request canceled", error.message);
        	} else {
          		console.error("Request failed", error);
        	}
      	});
  	};

	handlePasswordInput = (e) => {
    	if (e.key === "Enter") {
      		this.openPage(this.state.kanbanLockData.data[0].id);
    	}
  	};

  	openPage = (id) => {
		Axios.post(`${url}/delivery-sparepart/scan/shopping/open-page/${this.state.isAdmin ? id : this.state.kanbanLockData.data[0].id}`, {
	        password: this.state.password,
      	}, {
        	headers: {
          		Authorization: `Bearer ${localStorage.getItem("token")}`,
        	},
      	}).then((res) => {
        	const hasLockPage = res.data.data.length > 0 ? true : false;
        	this.setState({
          		...this.state,
          		isKanbanLock: hasLockPage,
          		kanbanLockData: {
            		data: this.state.kanbanLockData.data.filter(
              			(item) => item.id !== id
            		),
	            	isModalOpen: hasLockPage,
          		},
	        	hasLockPage: hasLockPage,
        		passwordError: "",
        		error: null,
        	});
        
			localStorage.setItem(
          		"isKanbanADMExportLock",
          		!this.state.isAdmin && hasLockPage ? res.data[0].lock : false
        	);
    	}).catch((error) => {
			this.setState({
				...this.state,
				passwordError: error.response.data.message,
			});
        
			if (!this.state.isAdmin) {
				this.passwordRef.focus();
			}
    	});
	};

	openPageAll = () => {
    	Axios.put(`${url}/delivery-sparepart/scan/shopping/open-all`, {}, {
	        headers: {
          		Authorization: `Bearer ${localStorage.getItem("token")}`,
        	},
      	}).then((res) => {
        	this.checkPage();
     	 }).catch((error) => {
        	console.error(error.response);
      	});
  	};

	handleOpenPage = () => {
    	if (this.state.isAdmin) {
      		this.openPageAll();
    	} else {
      		this.openPage(this.state.kanbanLockData.data[0].id);
    	}
  	};

	handleCloseModalLockPage = () => {
    	this.setState({
      		...this.state,
      		kanbanLockData: {
        		...this.state.kanbanLockData,
        		isModalOpen: !this.state.kanbanLockData.isModalOpen,
      		},
    	});
  	};

	componentDidMount = () => {
    	this.checkPage();
  	};

	render() {
    	const {
    		qrcode,
    		error,
    		fetching,
    		payload,
    		isKanbanLock,
    		kanbanLockData,
    		isAdmin,
    		passwordError,
    		lockpageOrdering,
    		hasLockPage
	    } = this.state;

    	const theads = [
    		{ name: "locked_by", value: `Locked By`, sortable: true },
    		{ name: "locked_at", value: "Time", sortable: true },
    		{ name: "message", value: "Reason", sortable: false },
    		{ name: "password", value: "Password", sortable: false },
    		{ name: "acton", value: "", sortable: false },
    	];

		if (error != null) {
			console.log(error.data);
		}

    	const lockTable = hasLockPage && kanbanLockData.data.map((data, index) => { //table locked view
    	    return (
    	    	<tr key={index}>
    	        	<td>{data.locked_by}</td>
    	        	<td>{data.locked_at}</td>
    	        	<td>{data.message}</td>
    	        	<td>{data.password}</td>
    	        	<td>
    	          		<Button onClick={() => this.openPage(data.id)}>Open</Button>
    	        	</td>
    	      	</tr>
    	    );
    	});

		if (!localStorage.getItem("token")) return <Redirect to="/login" />;
    	if (error && error.status === 500)
      		return <Error500 message={error.data.message} />;
    	if (error && error.status === 403)
      		return <Error403 message={error.data.message} />;

    	return (
			<Container fluid className="main-content-container px-4">
        		<Loading show={fetching} color="blue" showSpinner={false} />
        		<Helmet>
					<title>Scan Shopping Sparepart| {appName} </title>
        		</Helmet>
        		
				<Row noGutters className="page-header py-4">
          			<div className="col-md-8">
          				<PageTitle sm="12" title="Scan Shopping Sparepart" className="text-sm-left" />
          			</div>
        		</Row>

        		<Row>          
					{(isKanbanLock || kanbanLockData.isModalOpen) && (
						<Modal open={kanbanLockData.isModalOpen} toggle={this.handleCloseModalLockPage} size={isAdmin ? "lg" : "md"}>
							<ModalHeader>Page Lock!</ModalHeader>
							<ModalBody>
								{!isAdmin ? (
									<>
										<p>Halaman ini dikunci. Hubungi admin untuk membuka kunci.</p>
										<div className="form-group">
											<label className="control-label">{isAdmin ? "Ganti Password" : "Masukkan Password"}</label>
											<div className="input-group mb-3">
												<input type="password" id="password" ref={(input) => (this.passwordRef = input)}
														className={`form-control form-control-lg ${passwordError && "is-invalid"}`}
														onKeyDown={this.handlePasswordInput}
														onChange={this.handleChange}
												/>
												{passwordError && (
													<div className="invalid-feedback">
														{passwordError}
													</div>
												)}
											</div>
										</div>
									</>
								) : (
									<>
										<Table theads={theads} ordering={lockpageOrdering} handleSorting={this.handleSorting}>
											{lockTable}
										</Table>
									</>
								)}
							</ModalBody>
							<ModalFooter>
								<Button theme="primary" onClick={() => this.handleOpenPage()}>
									{isAdmin ? "Open All" : "Open"}
								</Button>
							</ModalFooter>
						</Modal>
					)}

          			<Col>
            			<Card small className="mb-4">
              				<CardBody className="p-0 pb-3">
                				<div className="col-md-12 mt-3">
                	  				<div className="row">
                	    				<div className="col-md-12">
                	    					{isAdmin && hasLockPage && (
                	        					<Button theme="secondary" className="mb-3" onClick={() =>
                	            					this.setState({
                	              						...this.state,
                	              						kanbanLockData: {
                	              							...this.state.kanbanLockData,
                	              							isModalOpen: true,
                	              						},
                	            					})}>
                	          						<span className="mdi mdi-lock-open-outline"></span>{" "} Unlock Page
                	        					</Button>
                	      					)}

                	      					<div className="form-group">
                	        					<label htmlFor="" className="control-label">Label Internal</label>
                	        					<input id="qrcode" type="text" className={`form-control form-control-lg ${error && error.data.message.qrcode_internal && "is-invalid "}`}
                	          							placeholder="Scan Kanban Internal here"
														onKeyDown={this.handleKeyDown}
														onChange={this.handleChange}
														readOnly={hasLockPage && (hasLockPage && !isAdmin)}
														value={qrcode}
                	        					/>
						                        {error && error.data.message.qrcode_internal && (
                	          						<div className="invalid-feedback">
                	          						  {error.data.message.qrcode_internal[0]}
                	          						</div>
                	        					)}
                	      					</div>
                	    				</div>
                	  				</div>
                				</div>

                				<div className="col-md-12 mt-4 mb-5">
                	  				<table className="table table-striped">
										<thead>
											<tr>
												<th>Part #</th>
											</tr>
										</thead>
										<tbody>
											{payload && payload.part ? (
												<tr>
													<td>{payload.part.code}</td>
												</tr>
											) : (
												<tr>
													<td colSpan="4" className="text-center">No Data</td>
												</tr>
											)}
										</tbody>
                	  				</table>
                				</div>
              				</CardBody>
            			</Card>
          			</Col>
        		</Row>
      		</Container>
    	);
  	}
}

export default withToastManager(ScanShoppingSparepart);
