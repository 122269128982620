import { combineReducers } from "redux";
import authReducer from "./authReducer";
import qrcodeReducer from "./qrcodeReducer";
import supplierReducer from "./supplierReducer";
import passwordReducer from "./passwordReducer";
import roleReducer from "./roleReducer";
import permissionReducer from "./permissionReducer";
import userReducer from "./userReducer";
import plantReducer from "./plantReducer";
import menuReducer from "./menuReducer";
import customerReducer from "./customerReducer";
import partReducer from "./partReducer";
import warehouseReducer from "./warehouseReducer";
import rackReducer from "./rackReducer";
import cycleReducer from "./cycleReducer";
import expeditionReducer from "./expeditionReducer";
import scanReducer from "./scanReducer";
import stockReducer from "./stockReducer";
import kanbanReducer from "./kanbanReducer";
import dnReducer from "./dnReducer";
import loadingReducer from "./loadingReducer";
import receivingReducer from "./receivingReducer";
import labelProductReducer from "./labelProdReducer";
import preparationDeliveryReducer from "./preparationDeliveryReducer";
import traceReducer from "./traceReducer";
import scanReportReducer from "./scanReportReducer";
import dnReportReducer from "./dnReportReducer";
import kanbanHPMReducer from "./kanbanHpmReducer";
import kanbanTMMINReducer from "./kanbanTmminReducer";
import qrcodeProductionReducer from "./qrcodeProductionReducer";
import kanbanADMExportReducer from "./kanbanAdmExportReducer";
import kanbanADMProdReducer from "./kanbanADMProdReducer";
import kanbanADMExportProdReducer from "./kanbanAdmExportProdReducer";
import moduleReportReducer from "./moduleReportReducer";
import kanbanInternalDnReducer from "./kanbanInternalDnReducer";
import orderADMSystemReportReducer from "./orderADMSystemReportReducer";
import monitoringStockReducer from "./monitoringStockReducer";
import dnSparepartReducer from "./dnSparepartReducer";
import orderSparepartSystemReportReducer from "./orderSparepartystemReportReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  qrcode: qrcodeReducer,
  qrcodeProduction: qrcodeProductionReducer,
  supplier: supplierReducer,
  password: passwordReducer,
  role: roleReducer,
  permission: permissionReducer,
  user: userReducer,
  plant: plantReducer,
  menu: menuReducer,
  customer: customerReducer,
  part: partReducer,
  warehouse: warehouseReducer,
  rack: rackReducer,
  cycle: cycleReducer,
  expedition: expeditionReducer,
  scan: scanReducer,
  stock: stockReducer,
  kanban: kanbanReducer,
  kanbanHPM: kanbanHPMReducer,
  kanbanTMMIN: kanbanTMMINReducer,
  kanbanADMExport: kanbanADMExportReducer,
  kanbanADMExportProd: kanbanADMExportProdReducer,
  kanbanADMProd: kanbanADMProdReducer,
  dn: dnReducer,
  loading: loadingReducer,
  receiving: receivingReducer,
  labelProduct: labelProductReducer,
  preparationDelivery: preparationDeliveryReducer,
  trace: traceReducer,
  scanReport: scanReportReducer,
  dnReport: dnReportReducer,
  moduleReport: moduleReportReducer,
  kanbanInternalDn: kanbanInternalDnReducer,
  orderADMSystemReport: orderADMSystemReportReducer,
  monitoringStock: monitoringStockReducer,
  deliveryNoteSparepart: dnSparepartReducer,
  orderSparepartReport: orderSparepartSystemReportReducer
});

export default rootReducer;
