const initState = {
    fetching: false,
    fetched: false,
    error: null,
    payload: {},
    customer: [],
    saved: false,
    message: null,
    isDeleted: false,
};

const dnSparepartReducer = (state = initState, action) => {
    switch (action.type) {
        case "REPORT_PENDING":
            return {
                ...state,
                fetching: true,
            };
        case "REPORT_REJECTED":
            if (action.payload.response.status === 401) {
                localStorage.removeItem("token");
            }
        
            return {
                ...state,
                error: action.payload.response,
                fetching: false,
            };
        case "REPORT_FULFILLED":
            return {
                ...state,
                fetching: false,
                fetched: true,
                payload: action.payload.data,
                error: null,
                saved: false,
                isDeleted: false,
            };
        case "GET_DETAIL_PENDING":
            return {
                ...state,
                fetching: true,
            };
        case "GET_DETAIL_REJECTED":
            if (action.payload.response.status === 401) {
                localStorage.removeItem("token");
            }
        
            return {
                ...state,
                error: action.payload.response,
                fetching: false,
            };
        case "GET_DETAIL_FULFILLED":
            return {
                ...state,
                fetching: false,
                fetched: true,
                customer: action.payload.data.data,
                error: null,
                saved: false,
            };
        default:
            return state;
    }
}

export default dnSparepartReducer;